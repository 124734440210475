import { useAtom } from "jotai";
import * as React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";

import Article from "@/components/Article/Article";
import AuthenticationGuard from "@/components/AuthenticationGuard/AuthenticationGuard";
import LockController from "@/components/LockController/LockController";
import Main from "@/components/Main/Main";
import PageLoadingBar from "@/components/PageLoadingBar/PageLoadingBar";
import PreviewBadge from "@/components/PreviewBadge/PreviewBadge";
import {
  authAtom,
  configAtom,
  helpPanelVisibleAtom,
  metaAtom,
  themeAtom,
} from "@/store";
import GlobalStyles from "@/styles/global-styles";
import { useAccessGuard } from "@/utils/hooks/useAccessGuard";
import { usePrevious } from "@/utils/hooks/usePrevious";
import Providers from "@/utils/Providers";
import { HtmlConfigViewModel } from "@/view-models/HtmlConfigViewModel";
import { MetaViewModel } from "@/view-models/MetaViewModel";

import { ModuleInstance, splitModules } from "./app-modules/modules-utils";
import ErrorBoundary from "./ErrorBoundary";
import { useRouter } from "./Router";
import ScrollRestoration from "./ScrollRestoration";
import { PageTracking } from "./tracking/tracking";
import useClickTracking from "./tracking/useClickTracking";

type Props = {
  modules: Array<ModuleInstance>;
  pathname: string;
  isLoading: boolean;
};

function renderModule(
  module: ModuleInstance | null | undefined,
  id?: string,
  debug = false,
  propertyTypeModalVisible?: boolean,
  goToPropertyTypeCallback?: any,
  meta?: MetaViewModel,
  config?: HtmlConfigViewModel
) {
  if (!module || !module.module) return null;

  if (module.name === "PropertyTypeModal") {
    module.props = {
      ...module.props,
      isVisible: propertyTypeModalVisible,
      cb: goToPropertyTypeCallback,
    };
  }

  return React.createElement(ErrorBoundary, {
    debug,
    module: module.name,
    key: module.key || id,
    children: React.createElement(module.module, {
      ...module.props,
      meta,
      config,
    }),
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime:
        typeof window === "undefined"
          ? Infinity // react-query uses a setTimout internally, which breaks the tests
          : 60000,
    },
  },
});

function AppShell({ modules, pathname, isLoading }: Props) {
  const { location, push } = useRouter();
  const previousLocation = usePrevious(location);
  const [config] = useAtom(configAtom);
  const { debug } = config;
  const [, setHelpPanelVisible] = useAtom(helpPanelVisibleAtom);
  const [meta] = useAtom(metaAtom);
  const [{ user, authenticating }] = useAtom(authAtom);

  const [propertyTypeModalVisible, setPropertyModalVisible] =
    React.useState(true);

  useAccessGuard();
  useClickTracking(!meta.behindAuth); // only track open pages with cookies approved

  const [theme] = useAtom(themeAtom);
  const split = splitModules(modules);

  React.useEffect(() => {
    setHelpPanelVisible(!!split.helpPanel);
    if (meta.title) {
      document.title = meta.title;
    } else if (meta.siteName) {
      document.title = meta.siteName;
    }
  }, [meta, split, setHelpPanelVisible]);

  // init tracking - it will only call pageTracking the first time
  React.useEffect(() => {
    PageTracking(meta, config, modules);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // tracking, call PageTracking when location is not the same as previous location
  React.useEffect(() => {
    if (previousLocation && location.pathname !== previousLocation.pathname) {
      PageTracking(meta, config, modules);
    }
  });

  React.useEffect(() => {
    if (meta.title) {
      document.title = meta.title;
    } else if (meta.siteName) {
      document.title = meta.siteName;
    }
  }, [meta]);

  const goToPropertyTypeCallback = (href) => {
    if (href) {
      const newUrl = location.pathname + href;
      push(newUrl);
    }
    setPropertyModalVisible(false);
  };

  const mainModules = (
    <>
      {split.preModules.map((module, index) => {
        if (
          module.name === "PropertyTypeModal" &&
          (meta?.behindAuth || user || authenticating)
        ) {
          return null;
        }

        return renderModule(
          module,
          `${pathname}_pre_${index}`,
          debug,
          propertyTypeModalVisible,
          goToPropertyTypeCallback
        );
      })}
      {split.article.length ? (
        <Article>
          {split.article.map((module, index) =>
            renderModule(module, `${pathname}_article_${index}`, debug)
          )}
        </Article>
      ) : null}
      {split.modules.map((module, index) =>
        renderModule(module, `${pathname}_${index}`, debug)
      )}
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <LockController>
          <GlobalStyles suppressMultiMountWarning />
          <PageLoadingBar loading={isLoading} />
          {!process.env.SERVER && <ScrollRestoration />}
          {renderModule(split.header, "header", debug)}
          <Main>
            <AuthenticationGuard
              behindAuth={meta.behindAuth}
              loginUrl={config.auth ? config.auth.login : ""}
            >
              <Providers>{mainModules}</Providers>
            </AuthenticationGuard>
          </Main>
          {renderModule(split.helpPanel, "help", debug)}
          {renderModule(split.footer, "footer", debug)}
          {config.previewLink ? (
            <PreviewBadge
              currentUrl={meta.absoluteUrl}
              link={config.previewLink}
            />
          ) : null}
        </LockController>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

AppShell.displayName = "AppShell";
AppShell.defaultProps = {
  modules: [],
  meta: {
    siteName: "Vurderingsportalen",
    behindAuth: false,
  },
};

export default AppShell;
