import * as React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  size?: "large" | "small" | "normal";
  title?: string;
};

function getWidth(size?: "small" | "large" | "normal") {
  if (size === "large") return "21";
  if (size === "small") return "10";

  return "14";
}
function getHeight(size?: "small" | "large" | "normal") {
  if (size === "large") return "22";
  if (size === "small") return "11";

  return "15";
}

export default ({ size, title, ...props }: Props) => {
  const [t, , ready] = useTranslation("common");

  return (
    <svg
      aria-label={title ? title : t("close")}
      height={getHeight(size)}
      viewBox="0 0 14 15"
      width={getWidth(size)}
      {...props}
    >
      {ready ? <title>{title ? title : t("close")}</title> : null}
      <path
        d="M0 0l14 14.433M14 0L0 14.433"
        fill="none"
        stroke="currentColor"
        strokeLinecap="square"
        strokeWidth="2"
      />
    </svg>
  );
};
