import { useAtom } from "jotai";
import React from "react";

import { Richtextdatapoints } from "@/graphql/__generated__/Richtextdatapoints";
import {
  getDatapointsFromData,
  RichTextDataPoints,
  useRichtext,
} from "@/graphql/datasets/RichtextQuery";
import { PreliminaryItemData } from "@/modules/PreliminaryAssessment/PreliminaryPropertyIntroAssessment/graphql/usePreliminaryProperty";
import {
  preliminaryPropertyDataAtom,
  publicAssessmentErrorAtom,
  publicAssessmentLoadingAtom,
  publicDataPointsAtom,
} from "@/store";
import { captureMessage } from "@/utils/error-logging";
import { PublicDataPoints } from "@/utils/hooks/usePublicAssessment";
import { DeklarationGeneralEnum } from "@/view-models/enums/DeklarationGeneralEnum";
import { DeklarationModalEnum } from "@/view-models/enums/DeklarationModalEnum";
import { PreliminaryGeneralEnum } from "@/view-models/enums/PreliminaryGeneralEnum";
import { PublicGeneralEnum } from "@/view-models/enums/PublicGeneralEnum";
import { VurderingGeneralEnum } from "@/view-models/enums/VurderingGeneralEnum";

type Props = { attributes: string; datapoints?: Richtextdatapoints };

// BE gets the data points to feed the Rich Text Area from the three types below, so everytime we
// extend this component with a new data point we should do it to the dataset type as well.
// We need to align with BE to which dataset type the new datapoint should be added.
type RichtextTypes =
  | DeklarationGeneralEnum
  | DeklarationModalEnum
  | VurderingGeneralEnum
  | PublicGeneralEnum
  | PreliminaryGeneralEnum;

type InternalProps = {
  name: RichtextTypes;
  bold?: string;
  labelledby?: string;
  publicAssessment?: boolean;
  preliminaryAssessment?: boolean;
  datapoints?: Richtextdatapoints;
};

const renderDataPoint = (
  name: string,
  datapoints: RichTextDataPoints | null | undefined
) => {
  switch (name) {
    case "vurderingsejendom_id":
      return datapoints?.vurderingsejendom_id;
    case "deklarationsperiode_slutdato":
      return datapoints?.deklarationsperiode_slutdato;
    case "afgoerelsesperiode_slutdato":
      return datapoints?.afgoerelsesperiode_slutdato;
    case "vurderingstermindato":
      return datapoints?.vurderingstermindato;
    case "vurderingsaar":
      return datapoints?.vurderingsaar;
    case "vurderingsbrevdato":
      return datapoints?.vurderingsbrevdato;
    case "grundvaerdi":
      return datapoints?.grundvaerdi;
    case "ejendomsvaerdi":
      return datapoints?.ejendomsvaerdi;
    case "indsigelse_sendt":
      return datapoints?.indsigelse_sendt;
    case "seneste_deklaration_brevdato":
      return datapoints?.seneste_deklaration_brevdato;
    case "bedste_anvendelse":
      return datapoints?.bedste_anvendelse;
    case "bedste_udnyttelse":
      return datapoints?.bedste_udnyttelse;
    case "grundareal":
      return datapoints?.grundareal;
    case "mindstegrundstoerrelse_valgt":
      return datapoints?.mindstegrundstoerrelse_valgt;
    case "foreloebig_vaerdi_udstykket":
      return datapoints?.foreloebig_vaerdi_udstykket;
    case "antal_mulige_udstykninger":
      return datapoints?.antal_mulige_udstykninger;
    case "tba_brevdato":
      return datapoints?.tba_brevdato;
    case "gtba_brevdato":
      return datapoints?.gtba_brevdato;
    case "gtbag_brevdato":
      return datapoints?.gtbag_brevdato;
    case "klagefrist_brevdato":
      return datapoints?.klagefrist_brevdato;
    case "historisk_vurdering_klagefristbrev_brevdato":
      return datapoints?.historisk_vurdering_klagefristbrev_brevdato;

    default:
      captureMessage(
        `Name for datapoint in richtext were not expected, ${name}`
      );
  }
};

const renderPublicDataPoint = (
  name: string,
  datapoints: PublicDataPoints | null | undefined
) => {
  switch (name) {
    case "adgangsadresse_id":
      return datapoints?.adgangsadresse_id;
    case "ejendomsvaerdi":
      return datapoints?.ejendomsvaerdi;
    case "grundvaerdi":
      return datapoints?.grundvaerdi;
    case "grundvaerdi_efter_fritagelse":
      return datapoints?.grundvaerdi_efter_fritagelse;
    case "jordrentepligtvaerdi":
      return datapoints?.jordrentepligtvaerdi;
    case "sag_id":
      return datapoints?.sag_id;
    case "vurderingsaar":
      return datapoints?.vurderingsaar;
    case "vurderingsejendom_id":
      return datapoints?.vurderingsejendom_id;

    default:
      captureMessage(
        `Name for datapoint in public richtext were not expected, ${name}`
      );
  }
};

const renderPreliminaryDataPoint = (
  name: string,
  datapoints: PreliminaryItemData | null | undefined
) => {
  switch (name) {
    case "adgangsAdresseID":
      return datapoints?.adgangsAdresseID;
    case "address":
      return datapoints?.address;
    case "propertyValue":
      return datapoints?.propertyValue;
    case "groundValue":
      return datapoints?.groundValue;
    case "vurderingsEjendomID":
      return datapoints?.vurderingsEjendomID;
    case "vurderingsaar":
      return datapoints?.vurderingsaar;
    case "juridiskKategori":
      return datapoints?.juridiskKategori;
    case "juridiskUnderkategori":
      return datapoints?.juridiskUnderkategori;

    default:
      captureMessage(
        `Name for datapoint in public richtext were not expected, ${name}`
      );
  }
};

const DataPointComponent = ({ name, bold, labelledby }: InternalProps) => {
  const { error, loading, data } = useRichtext();
  const Wrapper = bold ? "strong" : "span";

  if (error) {
    captureMessage(error.message);
  }

  return !loading && data !== undefined ? (
    <Wrapper aria-labelledby={labelledby} data-testid={"DataPointComponent"}>
      {renderDataPoint(name, data)}
    </Wrapper>
  ) : null;
};

const PreloadedDataPointComponent = ({
  name,
  bold,
  labelledby,
  datapoints,
}: InternalProps) => {
  const data = getDatapointsFromData(datapoints);
  const Wrapper = bold ? "strong" : "span";

  return data !== undefined ? (
    <Wrapper aria-labelledby={labelledby} data-testid={"DataPointComponent"}>
      {renderDataPoint(name, data)}
    </Wrapper>
  ) : null;
};

const PublicDataPointComponent = ({
  name,
  bold,
  labelledby,
}: InternalProps) => {
  const [loading] = useAtom(publicAssessmentLoadingAtom);
  const [error] = useAtom(publicAssessmentErrorAtom);
  const [publicDataPoints] = useAtom(publicDataPointsAtom);
  const Wrapper = bold ? "strong" : "span";

  if (error) {
    captureMessage(error.message);
  }

  return !loading && publicDataPoints !== undefined ? (
    <Wrapper aria-labelledby={labelledby} data-testid={"DataPointComponent"}>
      {renderPublicDataPoint(name, publicDataPoints)}
    </Wrapper>
  ) : null;
};

const PreliminaryDataPointComponent = ({
  name,
  bold,
  labelledby,
}: InternalProps) => {
  const [preliminaryDataPoints] = useAtom(preliminaryPropertyDataAtom);
  const Wrapper = bold ? "strong" : "span";

  return preliminaryDataPoints !== undefined ? (
    <Wrapper aria-labelledby={labelledby} data-testid={"DataPointComponent"}>
      {renderPreliminaryDataPoint(name, preliminaryDataPoints?.property)}
    </Wrapper>
  ) : null;
};

/**
 * @description Render Component for MarkDown
 * @param {string} attributes - A json string containing all props
 */
export const DataPointValue = ({ attributes, datapoints }: Props) => {
  try {
    const {
      name,
      bold,
      publicAssessment,
      preliminaryAssessment,
    }: InternalProps = JSON.parse(attributes);

    if (publicAssessment)
      return <PublicDataPointComponent bold={bold} name={name} />;
    if (preliminaryAssessment)
      return <PreliminaryDataPointComponent bold={bold} name={name} />;

    if (datapoints)
      return (
        <PreloadedDataPointComponent
          bold={bold}
          datapoints={datapoints}
          name={name}
        />
      );

    return <DataPointComponent bold={bold} name={name} />;
  } catch (e) {
    console.error(e);
  }

  return null;
};

DataPointValue.displayName = "DataPointValue";

export default DataPointValue;
