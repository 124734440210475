import * as React from "react";
import styled from "styled-components";

import List from "@/components/List/List";
import { FontSizes } from "@/styles/fonts";
import { mediaQuery } from "@/styles/media";

import { MaxWidth } from "../../markdown-styles";

type Props = {
  children?: React.ReactNode;
  ordered: boolean;
  size?: FontSizes;
  withMarginLeft?: boolean;
};

const StyledArticleList = MaxWidth.withComponent(styled(List)<{
  withMarginLeft?: boolean;
}>`
  margin-bottom: 1.875rem;
  margin-block-start: 0;

  ${(p) => (p.withMarginLeft ? `margin-left: 40px;` : undefined)}

  ${mediaQuery.xs`
    ${(p) => (p.withMarginLeft ? `margin-left: 20px;` : undefined)}
  `}

  > li {
    margin-bottom: 10px;
  }
`);

function ListRenderer({ children, ordered, size, withMarginLeft }: Props) {
  return (
    <div>
      <StyledArticleList
        ordered={ordered}
        size={size}
        withMarginLeft={withMarginLeft}
      >
        {children}
      </StyledArticleList>
    </div>
  );
}

ListRenderer.displayName = "ListRenderer";
ListRenderer.defaultProps = {
  ordered: false,
  withMarginLeft: false,
};

export default ListRenderer;
