import { useLazyQuery } from "@apollo/client";
import { useAtom } from "jotai";
import { debounce, isEqual } from "lodash";
import React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";

import { Location, useRouter } from "@/App/Router";
import FetchError from "@/components/Errors/FetchError/FetchError";
import Placeholder from "@/components/Placeholder/Placeholder";
import {
  GetVurderingsejendom,
  GetVurderingsejendomVariables,
} from "@/graphql/__generated__/GetVurderingsejendom";
import { VURDERINGSEJENDOM_FULL_QUERY } from "@/graphql/queries/baseQueries";
import { FullQueryResult, QueryHookOptions } from "@/graphql/types";
import { placeholderToggleAtom } from "@/store";
import { color } from "@/styles/theme";
import { useValuationYear } from "@/utils/hooks/useValuationYear";

const isOnWebPage = process.env.NODE_ENV !== "test" && !process.env.STORYBOOK;

const PropertyDataPlaceholder = styled(Placeholder)`
  min-height: inherit;
  z-index: 3;
  background-color: ${color.bg};

  ~ * {
    @supports (content-visibility: hidden) {
      opacity: 0;
      content-visibility: hidden !important;
    }
    @supports not (content-visibility: hidden) {
      display: none;
    }
  }
`;

type PropertyDataContextProviderProps = {
  children?: React.ReactNode;
};

type PropertyDataContextValue = {
  load: (options: QueryHookOptions) => Promise<any>;
  showPlaceholder?: boolean;
  setShowPlaceholder?: (bool: boolean) => void;
  queryResponse: FullQueryResult;
};

export const PropertyDataContext =
  React.createContext<PropertyDataContextValue>({} as PropertyDataContextValue);

const initialVariables: GetVurderingsejendomVariables = Object.freeze({
  visTilbageBetaling: false,
  visHistoriskVurderingssag: false,
  imageWidth: 1440,
  vurderingsaar: null,
  vurderingsejendom_id: -1,
  //* Directives enabling certain sections of the graphql query defined in baseQueries.js */
  visSagsdata: false,
  visIndsendelser: false,
  visVurdering: false,
  visDeklarationer: false,
  visDatagrundlag: false,
  visHeroBillede: false,
  visReferenceejendomme: false,
  visRichText: false,
  visKlagefristbrev: false,
});

/**
 * `PropertyDataContext` is used to make a combined graphql request for property data and passing it to consuming components using the `usePropertyData` hook.
 * ___
 * > Consumers specify what data they need from the full query and the `PropertyDataContext` combines their arguments into a single variables object.
 *
 * > This is to ensure that the amount of requests made on the page is as low as possible and to minimize overfetching of data not needed by any components on the page.
 */
export const PropertyDataContextProvider = ({
  children,
}: PropertyDataContextProviderProps) => {
  const { location } = useRouter();
  const key =
    process.env.NODE_ENV === "test"
      ? location.key
      : process.env.STORYBOOK
      ? (window as any)?.__STORYBOOK_STORY_STORE__?._selection?.storyId
      : location.pathname + location.search;

  const resultsMapRef = React.useRef<Map<string, any>>(new Map());
  const responseRef = React.useRef<{ [key: string]: (value: any) => void }>({});
  const variablesRef = React.useRef<
    GetVurderingsejendomVariables & { key?: string }
  >(initialVariables);
  const [showPlaceholder, setShowPlaceholder] = useAtom(placeholderToggleAtom);
  const mountedRef = React.useRef<boolean>(false);
  const resolversRef = React.useRef<((...args: Array<any>) => any)[]>([]);
  const previousLocationRef = React.useRef<Location>(location);
  const previousVariablesRef = React.useRef<
    GetVurderingsejendomVariables | null | undefined
  >(null);
  useValuationYear();

  const handleOnComplete = React.useCallback(
    (data) => {
      if (!mountedRef.current) return;

      if (
        variablesRef.current.key &&
        responseRef.current[variablesRef.current.key]
      ) {
        if (resultsMapRef.current.get(variablesRef.current.key) && !data) {
          // TODO: find out why data is being fetched twice (2nd time with undefined data)
          // on my properties page and remove this.
          return;
        } else {
          responseRef.current[variablesRef.current.key](data);
          resultsMapRef.current.set(variablesRef.current.key, data);
        }
      }

      resolversRef.current.forEach((resolve) => resolve(data));

      if (showPlaceholder) {
        setShowPlaceholder(false);
      }
    },
    [showPlaceholder, setShowPlaceholder]
  );

  const [fetchQuery, queryResponse] = useLazyQuery<
    GetVurderingsejendom,
    GetVurderingsejendomVariables
  >(VURDERINGSEJENDOM_FULL_QUERY, {
    variables: variablesRef.current,
    fetchPolicy: "cache-first",
    onCompleted: handleOnComplete,
  });

  // debounced load callback, runs latest load call from context consumer (usePropertyData)
  // (eslint complains about debounce not being an inline function, this can be safely ignored as calling debounce returns a function definition)
  // eslint-disable-next-line
  const debouncedFetch = React.useCallback(
    debounce(
      () => {
        if (mountedRef.current === true) {
          const variablesChanged =
            isEqual(initialVariables, variablesRef.current) === false &&
            isEqual(previousVariablesRef.current, variablesRef.current) ===
              false;
          if (
            variablesChanged &&
            variablesRef.current.vurderingsejendom_id !== -1
          ) {
            fetchQuery();
            previousVariablesRef.current = variablesRef.current;
          }
        }
      },
      100,
      {
        leading: false, // call the latest load call (the most recent called by a usePropertyData hook)
      }
    ),
    []
  );

  useQuery(
    ["propertyData", key],
    async ({ queryKey }) =>
      new Promise((resolve) => {
        if (responseRef.current[queryKey[1]]) {
          return responseRef.current[queryKey[1]];
        }
        responseRef.current[queryKey[1]] = resolve;
      })
  );

  // combines variables and calls debounced loadData
  const load = React.useCallback<(options: QueryHookOptions) => Promise<any>>(
    (options: QueryHookOptions) => {
      const vurderingsaar =
        options?.valuationYear ?? variablesRef.current?.vurderingsaar ?? null;
      const propertyId =
        options?.propertyId ??
        variablesRef.current?.vurderingsejendom_id ??
        null;

      // combine query variables object for request
      variablesRef.current = {
        ...variablesRef.current,
        ...options?.directives,
        vurderingsaar: Array.isArray(vurderingsaar)
          ? [...Array.from(new Set(vurderingsaar))] // filter to unique valuation years
          : [vurderingsaar],
        vurderingsejendom_id: propertyId,
        key,
      };

      return new Promise((resolve) => {
        resolversRef.current.push(resolve);
        if (options.skip) return;
        debouncedFetch();
      });
    },
    [debouncedFetch, key]
  );

  React.useEffect(() => {
    if (previousLocationRef.current.pathname !== location.pathname) {
      // reset variables reference to prepare for a new combined query on page change
      if (mountedRef.current) {
        setShowPlaceholder(false);
      }
    }
    previousLocationRef.current = location;

    return () => {
      previousVariablesRef.current = variablesRef.current;
      variablesRef.current = initialVariables;
    };
  }, [location, setShowPlaceholder]);

  React.useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const value = {
    load,
    queryResponse: {
      ...queryResponse,
      data: queryResponse?.data
        ? queryResponse?.data
        : resultsMapRef.current.has(key)
        ? resultsMapRef.current.get(key)
        : null,
    } as FullQueryResult,
    showPlaceholder,
    setShowPlaceholder,
  };

  const loading = queryResponse?.called ? queryResponse?.loading : true;

  const placeholderVisible =
    isOnWebPage && showPlaceholder // don't show placeholder in storybook or in tests
      ? loading
      : false;

  return (
    <PropertyDataContext.Provider value={value}>
      {placeholderVisible ? <PropertyDataPlaceholder /> : null}
      {value?.queryResponse?.error ? <FetchError /> : children}
    </PropertyDataContext.Provider>
  );
};
