import { AreaEnum } from "@/view-models/enums/AreaEnum";
import { ModuleViewModel } from "@/view-models/ModuleViewModel";
import { SectionAnchorViewModel } from "@/view-models/SectionAnchorViewModel";

export type ModuleInstance = {
  module: (...args: Array<any>) => any;
  name: string;
  key?: string;
  props: {
    [key: string]: any;
  } & {
    anchor?: SectionAnchorViewModel;
    area?: AreaEnum;
  };
};

export type SplitModules = {
  header?: ModuleInstance;
  anchorMenu?: ModuleInstance;
  breadcrumbs?: ModuleInstance;
  footer?: ModuleInstance;
  helpPanel?: ModuleInstance;
  preModules: Array<ModuleInstance>;
  propertyTypeModal: ModuleInstance;
  article: Array<ModuleInstance>;
  modules: Array<ModuleInstance>;
};

/**
 * When rendering serverside, fetch the module instance synchronously
 */
export function staticModules(
  modules: Array<ModuleViewModel>
): Array<ModuleInstance> {
  if (process.env.SERVER || process.env.NODE_ENV === "test") {
    // Ensure we only load the static modules map on the server
    const staticModulesMap = require("../../modules/staticModulesMap").default;

    return modules.reduce<ModuleInstance[]>(
      (acc, { name, key, properties }) => {
        const module = staticModulesMap(name);
        acc.push({
          module,
          name,
          key,
          props: properties,
        });
        if (!module) {
          // eslint-disable-next-line no-console
          console.error(`Tried to preload unknown React module: "${name}"`);
        }

        return acc;
      },
      []
    );
  }

  return [];
}

/**
 * When rendering on the client, preload module instance before rendering.
 * This ensures they can render when React is trying to render them
 */
export async function preloadModules(modules: Array<ModuleViewModel>) {
  const loadedModules: ModuleInstance[] = [];

  if (!process.env.SERVER) {
    // Ensure we only load the dynamic modules map on the client
    const dynamicModulesMap =
      require("../../modules/dynamicModulesMap").default;

    for (let i = 0; i < modules.length; i++) {
      const { name, key, properties } = modules[i];
      const loader = dynamicModulesMap(name);
      const module = loader ? await loader() : undefined;
      loadedModules.push({
        module: module?.default,
        name,
        key,
        props: properties,
      });
      if (!module) {
        // eslint-disable-next-line no-console
        console.error(`Tried to preload unknown React module: "${name}"`);
      }
    }
  }

  return loadedModules;
}

// A map of module names, and the `key` they should use for their instance
export const uniqueModulesMap = {
  AnchorMenu: "anchormenu",
  Breadcrumbs: "breadcrumbs",
};

/**
 * Split root modules from content modules
 **/
export function splitModules(modules: Array<ModuleInstance>): SplitModules {
  return modules
    .map((module) => {
      if (uniqueModulesMap[module.name]) {
        // Find modules that should have a fixed key across page views
        // This prevents the module from losing the state when changing page.
        module.key = uniqueModulesMap[module.name];
      }
      if (module.name === "AnchorMenu") {
        return {
          ...module,
          props: {
            ...module.props,
            anchors: modules.reduce((acc: any, mod) => {
              // Find all anchor values in the other modules, and add them to the AnchorMenu
              if (mod.props && mod.props.anchor) {
                acc.push(mod.props.anchor);
              }

              return acc;
            }, []),
          },
        };
      }

      return module;
    })
    .reduce(
      (acc: any, module) => {
        switch (module.name) {
          case "Header":
            acc.header = module;
            break;
          case "Footer":
            acc.footer = module;
            break;
          case "HelpPanel":
            acc.helpPanel = module;
            break;
          default:
            // Split modules on area, so we can article components pushed into a specific module
            if (module.props.area === "article") acc.article.push(module);
            else if (acc.article.length) acc.modules.push(module);
            else acc.preModules.push(module);
            break;
        }

        return acc;
      },
      {
        preModules: [],
        article: [],
        modules: [],
        header: undefined,
        footer: undefined,
        helpPanel: undefined,
      }
    );
}

/**
 * Flatten and return all unique module names
 **/
export function flatModulesList(
  modules: Array<ModuleViewModel> = []
): Array<string> {
  return [...new Set(modules.map((module) => module.name))];
}
