import {
  BrowserHistory,
  createBrowserHistory,
  createMemoryHistory,
  Location,
  MemoryHistory,
  MemoryHistoryOptions,
} from "history";
import { atom } from "jotai";

import { GetIndsendelser_vurderingsejendom_vurderingssager_indsendelser as CurrentDraftType } from "@/graphql/__generated__/GetIndsendelser";
import {
  IndsendelseTilstand,
  IndsendelseType,
} from "@/graphql/__generated__/globalTypes";
import { FilteredPreliminaryProperties } from "@/modules/PreliminaryAssessment/PreliminaryPropertyIntroAssessment/graphql/usePreliminaryProperty";
import { initialTheme, Theme } from "@/styles/theme";
import { HtmlConfigViewModel } from "@/view-models/HtmlConfigViewModel";
import { MetaViewModel } from "@/view-models/MetaViewModel";

import { GetUser_bruger_session as UserSession } from "../graphql/__generated__/GetUser";

export * from "./publicAssessment";

/**
  `Anchor Exclusion` is mainly used on the Assessment Page (Vurdering) and Reference Property module.
  ___
  It makes sure that in case the assessment is not ready, and thus VP won't be able to show the assessment modules,
  it will hide anchors in the anchor menu, even though they are specified in Umbraco.
  Each module that makes use of it, checks for Orange data and updates excluded anchors with `useExcludeAnchors` hook.
 */
export const anchorExclusionAtom = atom<string[], string[]>(
  [],
  (get, set, value) => set(anchorExclusionAtom, value)
);

/** controls whether or not help panel should be present on the page */
export const helpPanelVisibleAtom = atom<boolean, boolean>(
  false,
  (get, set, value) => {
    set(helpPanelVisibleAtom, value);
  }
);

/** controls the meta data of pages; e.g. title, description & required authentication etc. */
export const metaAtom = atom<MetaViewModel, MetaViewModel>(
  {} as MetaViewModel,
  (get, set, value) => {
    set(metaAtom, value);
  }
);

/**
 *  configuration that comes from umbraco, it contains various required information for the application;
 *  such as api endpoints, tracking and locale control.
 */
export const configAtom = atom<HtmlConfigViewModel, HtmlConfigViewModel>(
  {} as HtmlConfigViewModel,
  (get, set, value) => {
    set(configAtom, value);
  }
);

export type AuthAtomType = {
  /** Ready is true while we are validating if the user is or isn't logged in */
  authenticating: boolean;
  user?: UserSession | null;
  unauthenticating?: boolean | null;
};

/**
 * Used for controlling which layout we need to render based on `user` and `authenticating` status.
 * ___
 * Used to show user information in ex. LoginArea component or check if the user is correct Authenticated */
export const authAtom = atom<AuthAtomType, AuthAtomType>(
  {
    user: undefined,
    authenticating: true,
    unauthenticating: false,
  },
  (_get, set, value) => {
    set(authAtom, value);
  }
);

export const forbiddenErrorAtom = atom<boolean, boolean>(
  false,
  (get, set, value) => set(forbiddenErrorAtom, value)
);

export type ErrorPage = {
  title: string;
  description: string;
  ctas?: Array<{ label: string; href: string; external: boolean }>;
};
/**
 * Used to control the state of whether or not the user is logged in based on the status from the API.
 * ___
 * If user is not authenticated an error page is displayed.
 *
 * The value can be set from modules if business critical data is not present in API, ex. if the declaration PDF is missing.
 */
export const authGuardAtom = atom<ErrorPage | null, ErrorPage>(
  null,
  (get, set, value) => {
    set(authGuardAtom, value);
  }
);

/**
 * This is used to control the availability of pages that are locked behind authentication.
 * ___
 * It utilised in the `useAccessGuard` hook, which can trigger an invalid page state in the `authGuardAtom` when the session expires for example.
 */
export const accessDeniedAtom = atom<boolean, boolean>(
  false,
  (get, set, value) => set(accessDeniedAtom, value)
);

/**
 * This sets the open/closed state of the search bar at the top of the page.
 * ___
 * A use case could be opening the search bar from an article or accordion by clicking a button.
 */

export const searchIsOpenAtom = atom<boolean, boolean>(
  false,
  (get, set, value) => {
    set(searchIsOpenAtom, value);
  }
);

/**
 * Changing this will affect FloatingButton position
 */
export const adjustFaqPosition = atom<boolean, boolean>(
  false,
  (get, set, value) => {
    set(adjustFaqPosition, value);
  }
);

/** Controls the value of the current theme used by the `ThemeProvider`  */
export const themeAtom = atom<Theme, Theme>(initialTheme, (get, set, value) => {
  set(themeAtom, { ...get(themeAtom), ...value });
});

export type ValuationYearAtomType = {
  data?: any;
};
/**
 * 🔒 Requires Authentication
 * ___
 * This is for handling the collection of available valuation years the user has access to.
 * ___
 * After authentication it is set by fetching the `VALUATION_YEAR` query in the `useValuationYear` hook.
 */
export const valuationYearAtom = atom<
  ValuationYearAtomType | null,
  ValuationYearAtomType
>(null, (_get, set, value) => {
  set(valuationYearAtom, value);
});

export type ActiveYearAtomType = {
  [key: string | number]: {
    /** `activeYear` can either be set by the `System`  or by the `User` */
    setBy?: "User" | "System";
    year: number;
  };
};
/**
 * This is for handling current `activeYear` for a property.
 * ___
 * `activeYear` can either be set by the `system`, by fetching data from the Orange API and calculating latest year in `useValuationYear`.
 * or it can be set by a user, with `handleSetActiveYear`.
 */
export const activeYearAtom = atom<ActiveYearAtomType, ActiveYearAtomType>(
  {},
  (get, set, value) => {
    set(activeYearAtom, { ...get(activeYearAtom), ...value });
  }
);

/**
 * This is for handling the visibility of the global placeholder in the `PropertyDataContext`.
 * ___
 * It is toggled when a request is made for property data using the `usePropertyData` hook with the option `showPlaceholder` set to `true`.
 */
export const placeholderToggleAtom = atom<boolean, boolean>(
  false,
  (get, set, value) => {
    set(placeholderToggleAtom, value);
  }
);

/** history instance for managing the session history
 * ___
 * Used to set up event listeners on route changes and callbacks for controlling the flow of the application used by the `Router`.
 */
export const historyAtom = atom<
  BrowserHistory | MemoryHistory | undefined,
  BrowserHistory | MemoryHistory
>(
  process.env.SERVER ? undefined : createBrowserHistory(),
  (get, set, value) => {
    if (process.env.SERVER) {
      set(historyAtom, createMemoryHistory(value as MemoryHistoryOptions));
    } else {
      set(historyAtom, createBrowserHistory());
    }
  }
);

/** state of the current history location.
 * ___
 * A location contains information about the URL path, as well as possibly some arbitrary state and a key. */
export const locationAtom = atom<Location | undefined, Location>(
  undefined,
  (get, set, value) => {
    set(locationAtom, value);
  }
);

export const currentDraftAtom = atom<CurrentDraftType, CurrentDraftType>(
  {
    bilag: [],
    indhold: "",
    indsendelse_id: "",
    tilstand: IndsendelseTilstand.kladde,
    type: IndsendelseType.kontaktformular,
    opdateret: null,
    oprettet: null,
    vurderingsaar: null,
    vurderingsejendom_id: null,
  },
  (get, set, value) => {
    set(currentDraftAtom, value);
  }
);

export const contactGatewayAtom = atom<number, number>(0, (get, set, value) => {
  set(contactGatewayAtom, value);
});

export const groundPartTabAtom = atom<number, number>(0, (get, set, value) => {
  set(groundPartTabAtom, value);
});

export const partialPlotTabAtom = atom<number, number>(-1, (_, set, value) => {
  set(partialPlotTabAtom, value);
});

export const showRepresentativeAccessModalAtom = atom<boolean, boolean>(
  false,
  (get, set, value) => {
    set(showRepresentativeAccessModalAtom, value);
  }
);

export const houseUnitTabAtom = atom<number, number>(0, (get, set, value) => {
  set(houseUnitTabAtom, value);
});

export const referencePropertyTabAtom = atom<number, number>(
  0,
  (get, set, value) => {
    set(referencePropertyTabAtom, value);
  }
);

export const preliminaryPropertyDataAtom = atom<
  FilteredPreliminaryProperties | null,
  FilteredPreliminaryProperties
>(null, (get, set, value) => {
  set(preliminaryPropertyDataAtom, value);
});

export const anyPropertyRepresentedAtom = atom<boolean, boolean>(
  false,
  (get, set, value) => {
    set(anyPropertyRepresentedAtom, value);
  }
);

export const propertyDocumentsCurrentModal = atom<string | null, string | null>(
  null,
  (get, set, value) => {
    set(propertyDocumentsCurrentModal, value);
  }
);
