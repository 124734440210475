import { useAtom } from "jotai";
import * as React from "react";

import { Adresse, BfEnumre, Query } from "@/graphql/__generated__/publicTypes";
import {
  publicAddressAtom,
  publicAddressIdAtom,
  publicAssessmentAtom,
  publicAssessmentErrorAtom,
  publicAssessmentLoadingAtom,
} from "@/store";
import { captureMessage } from "@/utils/error-logging";

export type PublicDataPoints = {
  adgangsadresse_id?: string;
  ejendomsvaerdi?: string;
  grundvaerdi?: string;
  grundvaerdi_efter_fritagelse?: string;
  matrikel_betegnelse?: string;
  jordrentepligtvaerdi?: string;
  sag_id?: string;
  vurderingsaar?: number | null;
  vurderingsejendom_id?: number | null;
  juridisk_kategori?: string;
  bfe_nummer?: BfEnumre[] | undefined;
  ejerlav_matrikler: string | undefined;
};

export type UsePublicAssessmentType = {
  addressData: Adresse;
  hasPublicAssessment: boolean;
  publicAssessmentData?: Query;
  publicAssessmentError?: Error | null;
  loading?: boolean;
};

export const usePublicAssessment = (
  id?: string,
  type: "address" | "bfe" = "address"
): UsePublicAssessmentType => {
  const [, setPublicAddressId] = useAtom(publicAddressIdAtom);
  const [publicAddress] = useAtom(publicAddressAtom);
  const [loading] = useAtom(publicAssessmentLoadingAtom);
  const [publicAssessment] = useAtom(publicAssessmentAtom);
  const [publicAssessmentError] = useAtom(publicAssessmentErrorAtom);

  React.useEffect(() => {
    setPublicAddressId({ id, type });
  }, [setPublicAddressId, id, type]);

  if (publicAssessmentError) {
    captureMessage(
      `Error in loading Public Assessment data, ${publicAssessmentError}`
    );
  }

  const vurderingsejendoms_id =
    publicAssessment?.offentlige_vurderinger_paa_adresse?.[0]
      ?.vurderingsejendom_id ||
    publicAssessment?.offentlige_vurderinger_paa_bfe?.[0]?.vurderingsejendom_id;

  const hasPublicAssessment = !!vurderingsejendoms_id;

  return {
    addressData: publicAddress,
    hasPublicAssessment,
    publicAssessmentData: publicAssessment,
    publicAssessmentError,
    loading,
  };
};
