import * as React from "react";
import styled from "styled-components";

import { color } from "../../styles/theme";

export const DELAY_START = 250;
export const UPDATE_TIME = 150;
export const MAX_PROGRESS = 0.995;
export const PROGRESS_INCREASE = 0.075;
export const ANIMATION_DURATION = UPDATE_TIME * 4;
export const TERMINATING_ANIMATION_DURATION = UPDATE_TIME;

// Valid loading bar status
export const HIDDEN = "hidden";
export const STARTING = "starting";
export const RUNNING = "running";
export const IDLE = "idle";
export const STOPPING = "stopping";

type Props = {
  loading: boolean;
};

type BarProps = {
  animating: boolean;
};

type State = {
  status: "starting" | "running" | "stopping" | "hidden" | "idle";
  percent: number;
};

const StyledBar = styled.div`
  position: fixed;
  z-index: 10;
  height: 3px;
  width: 100%;
  top: 0;
  background: ${color.altBlue};
  transform-origin: left;
  transition: transform
    ${(props: BarProps) =>
      props.animating ? ANIMATION_DURATION : TERMINATING_ANIMATION_DURATION}ms
    linear;
  will-change: transform;
`;

/**
 * PageLoadingBar
 *
 * Renders a indeterminate loading bar at the top of the screen when switching page.
 */
class PageLoadingBar extends React.Component<Props, State> {
  static displayName = "PageLoadingBar";
  static defaultProps = {};

  static newPercent(percent: number, progressIncrease: number) {
    // Use cosine as a smoothing function
    return percent + progressIncrease * Math.cos(percent * (Math.PI / 2));
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.loading && [HIDDEN, STOPPING].includes(prevState.status)) {
      return { status: STARTING };
    }
    if (!nextProps.loading && prevState.status === STARTING) {
      return { status: HIDDEN };
    }
    if (!nextProps.loading && [RUNNING, IDLE].includes(prevState.status)) {
      return { status: STOPPING };
    }

    return null;
  }

  state: State = {
    status: HIDDEN,
    percent: 0,
  };

  componentDidMount() {
    if (this.state.status === STARTING) {
      this.delayStart();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.status !== this.state.status) {
      if (this.state.status === STARTING) {
        this.delayStart();
      } else if (this.state.status === RUNNING) {
        this.start();
      } else if (this.state.status === STOPPING) {
        this.stop();
      } else if (this.state.status === IDLE) {
        if (this.progressIntervalId) {
          clearInterval(this.progressIntervalId);
          this.progressIntervalId = undefined;
        }
      } else if (this.state.status === HIDDEN && this.delayTimeoutId) {
        clearTimeout(this.delayTimeoutId);
        this.delayTimeoutId = undefined;
      }
    }
  }

  componentWillUnmount() {
    if (this.delayTimeoutId) clearTimeout(this.delayTimeoutId);
    if (this.progressIntervalId) clearInterval(this.progressIntervalId);
    if (this.terminatingAnimationTimeoutId)
      clearTimeout(this.terminatingAnimationTimeoutId);
  }

  progressIntervalId: ReturnType<typeof setInterval> | undefined;
  delayTimeoutId: ReturnType<typeof setTimeout> | undefined;
  terminatingAnimationTimeoutId: ReturnType<typeof setTimeout> | undefined;

  delayStart() {
    if (!this.delayTimeoutId) {
      this.delayTimeoutId = setTimeout(() => {
        this.delayTimeoutId = undefined;
        this.setState({ status: RUNNING });
      }, DELAY_START);
    }
  }

  start() {
    if (this.delayTimeoutId) clearTimeout(this.delayTimeoutId);
    this.setState({ percent: 0 });
    this.progressIntervalId = setInterval(this.simulateProgress, UPDATE_TIME);
  }

  stop() {
    if (this.progressIntervalId) clearInterval(this.progressIntervalId);
    this.progressIntervalId = undefined;
    this.terminatingAnimationTimeoutId = setTimeout(
      this.reset,
      TERMINATING_ANIMATION_DURATION
    );
    this.setState({ percent: 1 });
  }

  reset = () => {
    this.terminatingAnimationTimeoutId = undefined;

    this.setState({
      percent: 0,
      status: HIDDEN,
    });
  };

  simulateProgress = () => {
    this.setState((prevState) => {
      const { percent } = prevState;
      const newPercent = PageLoadingBar.newPercent(
        percent || 0,
        PROGRESS_INCREASE
      );
      if (newPercent <= MAX_PROGRESS) {
        return { ...prevState, percent: newPercent };
      } else {
        return { ...prevState, status: IDLE };
      }
    });
  };

  render() {
    // If not active, don't render anything
    if (this.state.status === HIDDEN) return null;

    return (
      <StyledBar
        animating={this.state.status !== STOPPING}
        data-testid="loading-bar"
        style={{
          transform: `scaleX(${this.state.percent}) translateY(${
            this.state.status !== STOPPING ? "0" : "-100%"
          })`,
        }}
      />
    );
  }
}

export default PageLoadingBar;
