import {
  ApolloError,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
  useQuery,
} from "@apollo/client";
import { prop } from "ramda";
import React from "react";

import {
  GetIndsendelser,
  GetIndsendelser_vurderingsejendom_vurderingssager,
  GetIndsendelser_vurderingsejendom_vurderingssager_indsendelser,
  GetIndsendelserVariables,
} from "@/graphql/__generated__/GetIndsendelser";
import { IndsendelseType } from "@/graphql/__generated__/globalTypes";
import {
  createSubmissionMutation,
  DELETE_SUBMISSION,
  REMOVE_SUBMISSION_ATTACHMENT,
  SAVE_SUBMISSION,
  SUBMIT_SUBMISSION,
} from "@/graphql/queries/baseMutations";
import { GET_INDSENDELSER } from "@/graphql/queries/baseQueries";
import {
  getCurrentDraft,
  getLatestCase,
} from "@/modules/ContactAndObjection/Objection/ObjectionForm/private/helperFiles/filterFunctions";
import { PageContextViewModel } from "@/view-models/PageContextViewModel";

import reformatPayload from "../../modules/ContactAndObjection/Objection/ObjectionForm/private/helperFiles/reformatPayload";
import usePropertyIdAndValuationYear from "../hooks/usePropertyIdAndValuationYear";
import useQueryParams from "../hooks/useQueryParams";

/**
 * `Objection Context` is used for communication when User is adding a Indsigelse (Objection) to declaration.
 *  These information are mainly used in Objection flow, but we need to be able to show different status's, such as draft status in 'Mine ejendomme' list view.
 */
type Props = { children: React.ReactNode };
type State = { isReadyToSend: boolean };
type ObjectionContextType = {
  isReadyToSend: boolean;
  currentDraft:
    | GetIndsendelser_vurderingsejendom_vurderingssager_indsendelser
    | null
    | undefined
    | {
        [key: string]: any;
      };
  latestYearData?: GetIndsendelser_vurderingsejendom_vurderingssager | null;
  updateReadyToSendStatus: (isReadyToSend?: boolean) => void;
  ndStatus?: (arg0: boolean) => void;
  loading: boolean;
  error: any;
  caseId?: string | null;
  refetch: (...args: Array<any>) => any;
  getDraftById: (
    propertyId: number | null
  ) =>
    | GetIndsendelser_vurderingsejendom_vurderingssager_indsendelser
    | null
    | undefined;
  getLatestYearDataById: (
    id: number | null
  ) => GetIndsendelser_vurderingsejendom_vurderingssager | null | undefined;
  saveSubmission: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  saveLoading: boolean;
  deleteSubmission: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  deleteLoading: boolean;
  deleteAttachment: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  deleteAttachmentLoading: boolean;
  sendSubmission: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  sendLoading: boolean;
  saveError: ApolloError | undefined;
  sendError: ApolloError | undefined;
};

enum ActionType {
  UPDATE_VALID_TO_SEND = "UPDATE_VALID_TO_SEND",
}

interface IReducer {
  type: ActionType;
  isReadyToSend: boolean;
}

export const ObjectionStateContext = React.createContext<ObjectionContextType>({
  isReadyToSend: false,
  currentDraft: null,
  latestYearData: null,
  updateReadyToSendStatus: () => {},
  loading: false,
  error: null,
  caseId: null,
  refetch: () => {},
  getDraftById: () => undefined,
  getLatestYearDataById: () => undefined,
  saveLoading: false,
  deleteLoading: false,
  deleteAttachmentLoading: false,
  sendLoading: false,
  saveError: undefined,
  sendError: undefined,
  saveSubmission: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> => {
    throw new Error("Function not implemented.");
  },
  deleteSubmission: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> => {
    throw new Error("Function not implemented.");
  },
  deleteAttachment: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> => {
    throw new Error("Function not implemented.");
  },
  sendSubmission: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> => {
    throw new Error("Function not implemented.");
  },
});

const reducerFn: React.Reducer<State, IReducer> = (
  state: State,
  action?: { type: string; isReadyToSend: boolean }
) => {
  if (action && action.type === ActionType.UPDATE_VALID_TO_SEND) {
    return { ...state, isReadyToSend: action.isReadyToSend };
  } else {
    return state;
  }
};

const initialState: State = {
  isReadyToSend: true,
};

function ObjectionProvider({ children }: Props) {
  const { ejendomsid } = useQueryParams();

  const [{ isReadyToSend }, dispatch] = React.useReducer<
    React.Reducer<State, IReducer>
  >(reducerFn, initialState);

  const { data, loading, error, refetch } = useQuery<
    GetIndsendelser,
    GetIndsendelserVariables
  >(GET_INDSENDELSER, {
    variables: {
      vurderingsejendom_id: Number(ejendomsid),
    },
    skip: !ejendomsid,
  });

  const mutationOptions = {
    refetchQueries: [
      {
        query: GET_INDSENDELSER,
        variables: {
          vurderingsejendom_id: Number(ejendomsid),
        },
      },
    ],
    awaitRefetchQueries: true,
  };

  const [saveSubmission, { loading: saveLoading, error: saveError }] =
    useMutation(SAVE_SUBMISSION, mutationOptions);

  const [deleteSubmission, { loading: deleteLoading }] = useMutation(
    DELETE_SUBMISSION,
    mutationOptions
  );

  const [deleteAttachment, { loading: deleteAttachmentLoading }] = useMutation(
    REMOVE_SUBMISSION_ATTACHMENT,
    mutationOptions
  );

  const [sendSubmission, { loading: sendLoading, error: sendError }] =
    useMutation(SUBMIT_SUBMISSION, mutationOptions);

  // this is used only when there is propertyId in URL
  const [currentDraft, caseId, latestYearData] = React.useMemo(() => {
    if (!data || !ejendomsid) return [];

    const currentDraftFromData = getCurrentDraft(data, Number(ejendomsid));
    const latestYearFromData = getLatestCase(data, Number(ejendomsid));

    return [
      currentDraftFromData,
      prop("sag_id")(getLatestCase(data, Number(ejendomsid))),
      latestYearFromData,
    ];
  }, [data, ejendomsid]);

  // this is used when propertyId is not in the url (eg mine ejendomme)
  const getDraftById = React.useCallback(
    (propertyId: number | null) => {
      return data && propertyId ? getCurrentDraft(data, propertyId) : null;
    },
    [data]
  );

  // this is used when propertyId is not in the url (eg mine ejendomme)
  const getLatestYearDataById = React.useCallback(
    (propertyId: number | null | undefined) => {
      return data && propertyId ? getLatestCase(data, propertyId) : undefined;
    },
    [data]
  );

  // this is used on ObjectionSend module
  const updateReadyToSendStatus = React.useCallback(
    (isReadyToSend: boolean) => {
      dispatch({ type: ActionType.UPDATE_VALID_TO_SEND, isReadyToSend });
    },
    []
  );

  return (
    <ObjectionStateContext.Provider
      value={{
        currentDraft,
        latestYearData,
        getDraftById,
        getLatestYearDataById,
        isReadyToSend,
        updateReadyToSendStatus,
        error,
        loading,
        refetch,
        caseId,
        saveSubmission,
        deleteAttachment,
        deleteSubmission,
        sendSubmission,
        deleteAttachmentLoading,
        deleteLoading,
        saveLoading,
        sendLoading,
        saveError,
        sendError,
      }}
    >
      {children}
    </ObjectionStateContext.Provider>
  );
}

function useObjection() {
  const context = React.useContext(ObjectionStateContext);

  if (context === undefined) {
    throw new Error("useObjection must be used with ObjectionProvider");
  }

  return context;
}

function useCreateObjection(context: PageContextViewModel) {
  const { currentDraft, loading, caseId, saveSubmission } = useObjection();
  const { propertyId, valuationYear } = usePropertyIdAndValuationYear();

  const populateNewSubmission = React.useCallback(
    (indsendelse_id) => {
      const newPayloadJson = reformatPayload({
        context,
        values: {},
        currentDraft: {
          vurderingsejendom_id: propertyId,
          vurderingsaar: valuationYear,
        },
        caseId,
      });

      saveSubmission({
        variables: {
          indhold: newPayloadJson,
          indsendelse_id,
        },
      }).then(() => {});
    },
    [caseId, context, propertyId, saveSubmission, valuationYear]
  );

  const [create_submission, { called }] = useMutation(
    createSubmissionMutation,
    {
      ignoreResults: false,
      onCompleted: (data) => {
        populateNewSubmission(data.indsendelse_opret.indsendelse_id);
      },
    }
  );

  //create submission if no indsendelse_id
  React.useEffect(() => {
    if (!loading && !currentDraft?.indsendelse_id && !called) {
      create_submission({
        variables: {
          type: IndsendelseType.indsigelse,
          vurderingsaar: valuationYear,
          vurderingsejendom_id: propertyId,
        },
      });
    }
  }, [
    loading,
    currentDraft,
    called,
    create_submission,
    caseId,
    propertyId,
    valuationYear,
  ]);
}

ObjectionProvider.displayName = "ObjectionProvider";

export { ObjectionProvider, useCreateObjection, useObjection };
