import "unfetch/polyfill/index";

import { shouldPolyfill as shouldPolyfillIntl } from "@formatjs/intl-listformat/should-polyfill";

import addShims from "./polyfills/shims";

/**
 * Load additional polyfills here
 **/
function loadPolyfills(): Promise<unknown> {
  addShims();
  const polyfills: Promise<unknown>[] = [];

  if (shouldPolyfillIntl()) {
    polyfills.push(
      new Promise<void>((resolve) => {
        /* webpackChunkName: 'intl-listformat-polyfill' */ import(
          "@formatjs/intl-listformat/polyfill"
        ).then(() => {
          if (Intl?.ListFormat?.polyfilled) {
            return /* webpackChunkName: 'intl-listformat-polyfill-locale-da' */ import(
              "@formatjs/intl-listformat/locale-data/da"
            ).then(resolve);
          }

          resolve();
        });
      })
    );
  }

  if (!supportsSmoothScroll()) {
    polyfills.push(
      import(
        /* webpackChunkName: 'smoothscroll-polyfill' */
        "smoothscroll-polyfill"
      ).then((smoothscroll) => smoothscroll.polyfill())
    );
  }

  if (!supportsIntersectionObserver()) {
    polyfills.push(
      import(
        /* webpackChunkName: 'intersection-observer' */
        "intersection-observer"
      )
    );
  }
  if (!supportsSrcSet()) {
    polyfills.push(
      import(
        /* webpackChunkName: 'picturefill' */
        "./polyfills/picturefill"
      )
    );
  }

  if (!supportsUrlSearchParams()) {
    polyfills.push(
      import(
        /* webpackChunkName: 'url-polyfill' */
        "url-polyfill"
      )
    );
  }

  return Promise.all(polyfills);
}

function supportsIntersectionObserver() {
  return (
    "IntersectionObserver" in global &&
    "IntersectionObserverEntry" in global &&
    "intersectionRatio" in global.IntersectionObserverEntry.prototype
  );
}

function supportsSrcSet() {
  const img = document.createElement("img");

  return "sizes" in img;
}

function supportsUrlSearchParams() {
  try {
    const nativeURL = new global.URL("http://example.com");

    if ("href" in nativeURL && "searchParams" in nativeURL) {
      const url = new URL("http://example.com");
      url.search = "a=1&b=2";
      if (url.href === "http://example.com/?a=1&b=2") {
        url.search = "";
      }
    }

    return false;
  } catch (error) {
    return false;
  }
}

function supportsSmoothScroll() {
  return "scrollBehavior" in (document?.documentElement?.style || {});
}

export default loadPolyfills;
