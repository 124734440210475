import styled, { css } from "styled-components";

import { focusOutline } from "@/styles/style-helpers";

import { durations } from "../../styles/animations";
import { label } from "../../styles/components/label";
import { color, fontFamily } from "../../styles/theme";

export const StyledInputWrapper = styled.label`
  ${label};
  color: ${color.grey};
  margin-bottom: 5px;
  display: block;
  position: relative;
`;

export const StyledInputLabel = styled.label`
  ${label};
  margin-bottom: 5px;
`;

export const StyledLabel = styled.label`
  ${label};
  color: ${color.grey};
  margin-bottom: 10px;
  display: block;
  position: relative;
  line-height: 1;
`;

export const StyledSpan = styled.span`
  line-height: 22px;
`;

export const FieldBase = css<{ error?: boolean }>`
  width: 100%;
  appearance: none;
  font-size: 1rem;
  font-family: ${fontFamily};
  line-height: 1.63;
  font-weight: 400;
  border-radius: 0;
  background-color: ${color.white};
  color: ${color.blue};
  outline: none;
  text-align: left;
  padding: 12px 18px;
  caret-color: #72728a;
  transition-duration: ${durations.medium};
  ${focusOutline()};

  ${(p) =>
    p.error
      ? css`
          border: 1px solid ${color.red};
        `
      : css`
          border: 1px solid ${color.darkGrey};
        `}
`;

export const StyledField = styled.input<{
  error?: boolean;
  mainSearch?: boolean;
  propertySearch?: boolean;
  isMobile?: boolean;
  isFilterEnabled?: boolean;
}>`
  ${FieldBase}

  &:disabled {
    color: #bdbdc3;
  }

  &:focus {
    ${(p) => (p.propertySearch ? "outline: none;" : null)}
  }

  ${(p) =>
    p.propertySearch
      ? `
      border: 1px solid ${color.paleLilac};
      ${
        p.isMobile && (p.isFilterEnabled === undefined || p.isFilterEnabled)
          ? "border-top-width: 0px;"
          : undefined
      }
      `
      : undefined}

  ${(p) =>
    p.mainSearch
      ? `border: none;
      height: 84px;
      &:focus {
        outline: 1px solid white;
      };`
      : undefined}

  &:hover {
    border-color: ${(p) => (p.error ? color.red : color.formHover)};
  }

  &:invalid {
    box-shadow: inherit;
  }

  /* stylelint-disable no-descending-specificity */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    /* Remove the spin buttons in number fields */
    /* stylelint-disable */
    -webkit-appearance: none;
    margin: 0;
  }

  /* Hide default search-related buttons */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-clear-button {
    -webkit-appearance: none;
  }
  /* stylelint-enable */

  &::-webkit-calendar-picker-indicator {
    background: none;
  }

  ::placeholder {
    color: ${color.grey};
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder {
    color: ${color.grey};
  }
  ::-ms-input-placeholder {
    color: ${color.grey};
  }
`;

// Set the minimum height to lines * line-height + padding
export const StyledTextArea = styled(StyledField.withComponent("textarea"))`
  min-height: calc(3em * 1.63 + 24px);
`;
