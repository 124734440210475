import { useAtom } from "jotai";
import * as React from "react";

import { accessDeniedAtom, authGuardAtom } from "@/store";
import { ACCESS_DENIED } from "@/utils/actions";
import { subscribe, unsubscribe } from "@/utils/dispatcher";

export function useAccessGuard() {
  const [, setPageInvalid] = useAtom(authGuardAtom);
  const [accessDenied, setAccessDenied] = useAtom(accessDeniedAtom);

  React.useEffect(() => {
    if (accessDenied) {
      setPageInvalid({
        title: "accessDeniedHeadline",
        description: "accessDeniedBody",
        ctas: [
          {
            label: "accessDeniedButtonLabel",
            href: "/",
            external: true,
          },
        ],
      });
    }
  }, [setPageInvalid, accessDenied]);

  React.useEffect(() => {
    const handleAccessDenied = () => {
      setAccessDenied((val) => {
        return !val ? true : val;
      });
    };
    subscribe(ACCESS_DENIED, handleAccessDenied);

    return () => {
      unsubscribe(ACCESS_DENIED, handleAccessDenied);
    };
  }, [setAccessDenied]);
}
