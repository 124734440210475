import * as React from "react";

import { isExternalLink } from "../../components/Link/Link";
import {
  DATA_TRACKING_EVENT_NAME,
  DATA_TRACKING_FILE_NAME,
  DATA_TRACKING_FILE_TYPE,
  DATA_TRACKING_FILE_URL,
  DATA_TRACKING_LEVEL,
  DATA_TRACKING_LINK_TITLE,
  DATA_TRACKING_NAME,
  DATA_TRACKING_SECTION,
  DATA_TRACKING_SUB_TITLE,
  DATA_TRACKING_TITLE,
  DATA_TRACKING_TYPE,
} from "./tracking";

export default function useClickTracking(active: boolean) {
  React.useEffect(() => {
    const trackClick = (e) => {
      //find all interactive elements
      const el = e.target.closest(`[${DATA_TRACKING_SECTION}]`);
      const elEvName = el
        ? el.attributes[`${DATA_TRACKING_EVENT_NAME}`].value
        : "";

      //This is a whitelist of tracking events that is allowed when you are logged in and authenticated.
      const includedTrackingFields = [
        "mapThumbnailOpen",
        "mapThumbnailClose",
        "foldOutLinkClick",
        "downloads",
        "fileDownload",
        "hjælpepanel",
        "hjælpepanelOpen",
        "hjælpepanelCatagoryClicked",
        "ctaClicks",
        "accordionOpen",
        "helpIconButtonClicked",
      ];

      if (
        !active &&
        el &&
        window.digitalData &&
        window._satellite &&
        window._satellite.track &&
        includedTrackingFields.includes(elEvName)
      ) {
        handleTracking(el);
      }

      if (!active && el && !includedTrackingFields.includes(elEvName)) {
        e.stopPropagation();
      }

      if (
        active &&
        el &&
        window.digitalData &&
        window._satellite &&
        window._satellite.track
      ) {
        handleTracking(el);
      }
    };

    window.addEventListener("click", trackClick);

    return () => {
      window.removeEventListener("click", trackClick);
    };
  });
}

function handleTracking(element) {
  const fileName = element.attributes[`${DATA_TRACKING_FILE_NAME}`].value;
  const fileType = element.attributes[`${DATA_TRACKING_FILE_TYPE}`].value;
  const fileUrl = element.attributes[`${DATA_TRACKING_FILE_URL}`].value;
  const eventNameValue =
    element.attributes[`${DATA_TRACKING_EVENT_NAME}`].value;
  const sectionValue = element.attributes[`${DATA_TRACKING_SECTION}`].value;
  const type = element.attributes[`${DATA_TRACKING_TYPE}`];
  const name = element.attributes[`${DATA_TRACKING_NAME}`];
  const title = element.attributes[`${DATA_TRACKING_TITLE}`];
  const subTitle = element.attributes[`${DATA_TRACKING_SUB_TITLE}`];
  const linkTitle = element.attributes[`${DATA_TRACKING_LINK_TITLE}`];
  //Data tracking level indicateds if the value should not be tracked, 0 means not tracking.
  const dataTrackingLevel = element.attributes[`${DATA_TRACKING_LEVEL}`];
  const hrefValue =
    element.attributes["href"] && element.attributes["href"].value;
  let linkStatus = "";
  if (hrefValue) {
    linkStatus = isExternalLink(hrefValue) ? "external-link" : "internal-link";
  }

  window.digitalData[sectionValue] = {}; //first wipe digitalData from the previous values

  switch (eventNameValue) {
    case "articleLinkClick":
    case "anchorLinkClick":
    case "emnesideLinkClick":
    case "mapThumbnailClose":
    case "mapThumbnailOpen":
    case "downloads":
      window.digitalData[sectionValue] = {
        linkName: `${linkStatus}: ${name && name.value}`.toLowerCase(),
      };

      window._satellite.track(eventNameValue);
      break;
    case "fileDownload":
      window.digitalData[sectionValue] = {
        fileName: fileName,
        fileType: fileType,
        fileLinkURL: fileUrl,
      };

      window._satellite.track(eventNameValue);
      break;
    case "phone_clicked":
      window.digitalData[sectionValue] = {
        name: name && name.value.toLowerCase(),
      };

      window._satellite.track(eventNameValue);

      break;
    case "foldOutLinkClick":
      window.digitalData[sectionValue] = {
        name: name && name.value.toLowerCase(),
        type: type && type.value.toLowerCase(),
      };

      window._satellite.track(eventNameValue);
      break;
    case "ctaClicks":
      //Data tracking level 0 means the value should not be tracked.
      if (dataTrackingLevel !== "0") {
        window.digitalData[sectionValue] = {
          buttonName: name && name.value.toLowerCase(),
        };

        window._satellite.track(eventNameValue);
      }

      break;
    case "accordionOpen":
      // only track when it's open
      if (element.attributes["aria-expanded"].value === "true") {
        window.digitalData[sectionValue] = {
          name: name && name.value.toLowerCase(),
          type: type && type.value.toLowerCase(),
        };
        window._satellite.track(eventNameValue);
      }

      break;
    case "accordionLinkClick":
      window.digitalData[sectionValue] = {
        name: `${linkStatus}: ${name && name.value}`.toLowerCase(),
        type: linkStatus,
      };

      window._satellite.track(eventNameValue);

      break;
    case "hjælpepanelOpen":
    case "helpIconButtonClicked":
      window.digitalData[sectionValue] = {
        name: name && name.value.toLowerCase(),
        type: type && type.value.toLowerCase(),
      };

      window._satellite.track(eventNameValue);
      break;
    case "hjælpepanelLinkClicked":
    case "hjælpepanelCatagoryClicked":
    case "hjælpepanelArticleClicked":
    case "hjælpepanelExternalLinkClicked":
      window.digitalData[sectionValue] = {
        title: title && title.value.toLowerCase(),
        subTitle: subTitle && subTitle.value.toLowerCase(),
        type: type && type.value.toLowerCase(),
        linkTitle: linkTitle ? linkTitle.value.toLowerCase() : "",
      };

      window._satellite.track(eventNameValue);

      break;

    case "heroCardClicked":
    case "displayListClicked":
    case "promotedContentClicked":
    case "relatedPagesClicked":
      window.digitalData[sectionValue] = {
        name: name && name.value.toLowerCase(),
        type: linkStatus,
      };

      window._satellite.track(eventNameValue);
      break;
    default:
      return;
  }
}
