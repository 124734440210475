import { compiler } from "markdown-to-jsx";
import * as React from "react";
import styled from "styled-components";

import { Richtextdatapoints } from "@/graphql/__generated__/Richtextdatapoints";

import { FontSizes } from "../../styles/fonts";
import {
  clearMargin,
  getFontSize,
  getLineHeight,
} from "../../styles/style-helpers";
import Anchorlink from "./renderers/Anchorlink/Anchorlink";
import BlockquoteRenderer from "./renderers/BlockquoteRenderer/BlockquoteRenderer";
import DataPointValue from "./renderers/DataPointValue/DataPointValue";
import HeaderRenderer from "./renderers/HeaderRenderer/HeaderRenderer";
import HelpLink, { StyledHelpLink } from "./renderers/HelpLink/HelpLink";
import ImageRenderer from "./renderers/ImageRenderer/ImageRenderer";
import LinkRenderer from "./renderers/LinkRenderer/LinkRenderer";
import ListRenderer from "./renderers/ListRenderer/ListRenderer";
import ParagraphRenderer from "./renderers/ParagraphRenderer/ParagraphRenderer";
import TableRenderer from "./renderers/TableRenderer/TableRenderer";
import Underline from "./renderers/Underline/Underline";
import Vurderingstermin from "./renderers/Vurderingstermin/Vurderingstermin";

export type MarkdownProps = {
  source?: string | null | undefined;
  className?: string;

  /** How much margin should be used between blocks. This will affect headers and images. Default is `large`, which matches the intended margin between elements */
  blockMargin?: "small" | "large" | "normal";
  size?: FontSizes;
  maxWidth?: boolean;
  overrides?: {
    [key: string]: {
      component: React.ComponentType<any>;
      props?: {
        [key: string]: any;
      };
    };
  };
  isAccordion?: boolean;
  datapoints?: Richtextdatapoints;
  // Added as own property, as maxWidth previously only referred to the title and this might break some layouts
  paragraphMaxWidth?: boolean;
};

const StyledWrapper = styled.div<{ size?: FontSizes }>`
  font-size: ${(p) => (p.size ? getFontSize(p.size) : undefined)};
  line-height: ${(p) => (p.size ? getLineHeight(p.size) : undefined)};
  /* this one is applicable only when there is one element in the "source" */
  ${clearMargin()}
  /* when there is more children Markdown wraps them in a div and the "> first-child" is applicable */
  > :first-child {
    ${clearMargin()};
  }

  ${StyledHelpLink} {
    margin-top: 20px;
  }

  ${StyledHelpLink} + ${StyledHelpLink} {
    margin-top: 20px;
  }

  ${StyledHelpLink} ~ * {
    margin-top: 10px;
  }
`;

function Markdown({
  source,
  className,
  blockMargin,
  size,
  overrides,
  isAccordion,
  maxWidth = false,
  paragraphMaxWidth = true,
  datapoints,
}: MarkdownProps) {
  // You need a markdown source
  if (!source) return null;

  const children = compiler(source, {
    forceBlock: true,
    overrides: {
      h1: {
        component: HeaderRenderer,
        props: { level: 1, marginTop: blockMargin },
      },
      h2: {
        component: HeaderRenderer,
        props: { level: 2, marginTop: blockMargin, maxWidth },
      },
      h3: {
        component: HeaderRenderer,
        props: { level: 3, marginTop: "small" },
      },
      h4: {
        component: HeaderRenderer,
        props: { level: 4, marginTop: blockMargin },
      },
      h5: {
        component: HeaderRenderer,
        props: { level: 5, marginTop: blockMargin },
      },
      h6: {
        component: HeaderRenderer,
        props: { level: 6, marginTop: blockMargin },
      },
      img: {
        component: ImageRenderer,
        props: {
          margin: blockMargin,
        },
      },
      a: {
        component: LinkRenderer,
        props: {
          isAccordion,
        },
      },
      p: {
        component: ParagraphRenderer,
        props: {
          size,
          isAccordion,
          maxWidth: paragraphMaxWidth,
        },
      },
      blockquote: {
        component: BlockquoteRenderer,
      },
      ol: {
        component: ListRenderer,
        props: {
          ordered: true,
          size,
        },
      },
      ul: {
        component: ListRenderer,
        props: {
          size,
        },
      },
      helplink: {
        component: HelpLink,
      },
      vurderingstermin: {
        component: Vurderingstermin,
      },
      underline: {
        component: Underline,
      },
      datapointvalue: {
        component: DataPointValue,
        props: {
          datapoints,
        },
      },
      anchorlink: {
        component: Anchorlink,
        props: {
          isAccordion,
        },
      },
      image: {
        component: ImageRenderer,
        props: {
          margin: blockMargin,
        },
      },
      table: {
        component: TableRenderer,
      },
      ...overrides,
    },
  });

  return (
    <StyledWrapper className={className} size={size}>
      {children.type === "span" ? children.props.children : children}
    </StyledWrapper>
  );
}

Markdown.displayName = "Markdown";
Markdown.defaultProps = {
  blockMargin: "large",
  overrides: {},
};

export default React.memo(Markdown);
