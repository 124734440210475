import useId from "@charlietango/use-id";
import { rem } from "polished";
import * as React from "react";
import styled, { css } from "styled-components";

import HelpIconButton from "@/components/HelpIconButton/HelpIconButton";
import { mediaOnly } from "@/styles/media";
import { responsiveFont } from "@/styles/style-helpers";
import { color, fontFamily } from "@/styles/theme";
import { HelpButtonViewModel } from "@/view-models/HelpButtonViewModel";

type StyledProps = {
  marginTop?: "large" | "normal" | "small" | "none";
  marginBottom?: "xlarge" | "large" | "normal" | "none";
  responsiveSize?: boolean;
  maxWidth?: boolean;
  isArticle?: boolean;
};

const baseStyle = css<StyledProps>`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-family: ${fontFamily};
  line-height: 1.13;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  color: ${(p) => (p.theme.style === "dark" ? color.white : color.blue)};

  margin-top: ${(p) => {
    if (p.marginTop === "small") return "30px";
    if (p.marginTop === "normal") return "40px";
    if (p.marginTop === "large") return "80px";

    return "0";
  }};
  margin-bottom: ${(p) => {
    if (p.marginBottom === "normal") return "10px";
    if (p.marginBottom === "large") return "20px";
    if (p.marginBottom === "xlarge") return "30px";

    return "0";
  }};

  ${mediaOnly.xs`
      margin-top: ${(p) => {
        if (p.marginTop === "small") return "30px";
        if (p.marginTop === "normal") return "40px";
        if (p.marginTop === "large") return "40px";

        return undefined;
      }};
      margin-bottom: ${(p) => {
        if (p.marginBottom === "xlarge") return "30px";

        return undefined;
      }};
  `};
`;

type Props = {
  /** The visual size of the Heading */
  level: 1 | 2 | 3 | 4 | 5 | 6;

  /** The actual HTML element that should be rendered in the browser */
  tag?: React.ComponentType<any> | string;

  /** If children is an array of nodes, they will be separated by <br/> elements */
  children?: React.ReactNode;

  isArticle?: boolean;

  /** Don't split the children into multiple headings */
  noBreaks?: boolean;
  marginTop?: "large" | "normal" | "small" | "none";
  marginBottom?: "xlarge" | "large" | "normal" | "none";
  responsiveSize?: boolean;
  help?: HelpButtonViewModel;
  maxWidth?: boolean;
  id?: string;
};

const StyledH1 = styled.h1<StyledProps>`
  ${baseStyle}
  ${(p) =>
    !p.responsiveSize ? `font-size: ${rem(64)};` : responsiveFont(28, 64)};
  font-weight: 900;
  margin-bottom: ${(p) => {
    if (p.marginBottom === "normal") return "25px";
    if (p.marginBottom === "large") return "40px";
  }};

  ${mediaOnly.xs`
    font-size: ${rem(28)}

    margin-bottom: ${(p) => {
      if (p.marginBottom === "normal") return "20px";
      if (p.marginBottom === "large") return "30px";
    }};
  `};
`;

const StyledH2 = styled.h2<StyledProps>`
  ${baseStyle};
  ${(p) =>
    !p.responsiveSize ? `font-size: ${rem(32)};` : responsiveFont(22, 32)};
  font-weight: 700;

  ${mediaOnly.xs`
    font-size: ${rem(22)}
  `};
`;

const StyledH3 = styled.h3<StyledProps>`
  ${baseStyle};
  ${(p) => {
    if (p.isArticle)
      return !p.responsiveSize
        ? `font-size: ${rem(28)};`
        : responsiveFont(21, 28);

    return !p.responsiveSize
      ? `font-size: ${rem(25)};`
      : responsiveFont(18, 25);
  }};

  ${(p) => {
    if (p.isArticle)
      return `
        line-height: 1.428;
        font-weight: 700;
      `;

    return `
      line-height: 1.36;
      font-weight: 600;
    `;
  }};

  ${mediaOnly.xs`
    font-weight: 600;
  
    ${(p: { isArticle: boolean }) => {
      if (p.isArticle)
        return `
        font-size: ${rem(21)};
        line-height: 1.38;
      `;
      else
        return `
    font-size: ${rem(18)};
    line-height: 1.5;
    `;
    }}    
  `}
`;

const StyledH4 = styled.h4<StyledProps>`
  ${baseStyle};
  ${(p) => {
    if (p.isArticle)
      return `
    font-size: ${rem(25)};
    font-weight: 700;
    line-height: 1.44;
    `;

    return `
    font-size: ${rem(20)};
    font-weight: 600;
    line-height: 1.4;
    `;
  }};

  max-width: ${(p) =>
    p.maxWidth ? (p.isArticle ? "640px" : "550px") : undefined};

  ${mediaOnly.xs`
    font-weight: 600;

    ${(p: { isArticle: boolean }) => {
      if (p.isArticle)
        return `
        font-size: ${rem(18)};
        line-height: 1.5;
      `;
      else
        return `
    font-size: ${rem(16)};
    `;
    }}    
  `}
`;

const StyledH5 = styled.h5<StyledProps>`
  ${baseStyle};
  ${(p) => {
    if (p.isArticle)
      return `
    font-size: ${rem(20)};
    `;

    return `
    font-size: 1rem;
    `;
  }};
  font-weight: 600;

  ${mediaOnly.xs`
    font-size: ${rem(15)}
  `};
`;

const StyledH6 = styled.h6`
  ${baseStyle};
  font-size: ${rem(14)};
  font-weight: 500;
  line-height: 1.64;
`;

// Add the styles to array for easy lookup
const sizes = [StyledH1, StyledH2, StyledH3, StyledH4, StyledH5, StyledH6];

/**
 * Render a text heading
 */
const Heading = (props: Props) => {
  const {
    level,
    tag,
    children,
    marginTop,
    marginBottom,
    noBreaks,
    help,
    maxWidth = false,
    isArticle,
    ...rest
  } = props;
  const Styled = sizes[level - 1];
  const id = useId();
  const headingId = `heading-${id}`;

  return (
    <Styled
      as={tag ? tag : undefined}
      id={help ? headingId : null}
      isArticle={isArticle}
      marginBottom={marginBottom}
      marginTop={marginTop}
      maxWidth={maxWidth}
      {...rest}
    >
      {!noBreaks
        ? React.Children.map(children, (child, index) =>
            index > 0 ? [<br key="br" />, child] : child
          )
        : children}
      {help ? <HelpIconButton ariaDescribedBy={headingId} help={help} /> : null}
    </Styled>
  );
};

Heading.defaultProps = {
  level: 2,
  marginBottom: "normal",
};

export default Heading;
